// Before uncommenting please read the comments of scss/styleguide.scss
@import 'styles/styleguide';

$img-path: './assets/images/';

// Images
$bgBlackWidowChar: $img-path+'black-widow-char.jpg';
$bgBlackWidowEye: $img-path+'black-widow-eye.png';
$bgCaptainMarvelChar: $img-path+'captain-marvel-char.jpg';
$bgIronManChar: $img-path+'iron-man-char.jpg';
$bgIronManRain: $img-path+'iron-man-rain.gif';
$bgDeadPoolChar: $img-path+'deadpool-char.gif';
$bgCaptainAmericaChar: $img-path+'captain-america-char.png';
$bgCaptainAmericaSmoke: $img-path+'captain-america-smoke.jpg';
$bgCaptainAmericaGlitch1: $img-path+'captain-america-glitch-1.png';
$bgCaptainAmericaGlitch2: $img-path+'captain-america-glitch-2.png';
$bgCaptainAmericaGlitch3: $img-path+'captain-america-glitch-3.png';
$bgAntManChar: $img-path+'ant-man-char.jpg';
$bgAntManCharSmall: $img-path+'ant-man-char-small.jpg';
$bgAntManStatic: $img-path+'ant-man-static.jpg';
$bgThorChar: $img-path+'mjolnir-char.jpg';
$bgMaintenance: $img-path+'maintenance.jpg';

@keyframes pulse {
  30% {
    transform: scale(1);
  }

  40% {
    transform: scale(1.02);
  }

  50% {
    transform: scale(1);
  }

  60% {
    transform: scale(1);
  }

  70% {
    transform: scale(1.01);
  }

  80% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes glitch {
  0% {
    background-position: center, center, center;
  }

  25% {
    background-position: center, -5px 2px, 4px 5px;
  }

  50% {
    background-position: center, 2px 6px, -1px -1px;
  }

  75% {
    background-position: center, -2px 4px, 4px 8px;
  }

  100% {
    background-position: center, 3px 7px, -3px 0px;
  }
}

@keyframes shift {
  0% {
    background-position: 0 center;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    background-position: -960px center;
    opacity: 0.4;
  }
}

body,
html {
  height: 100%;
}

header {
  width: 100%;
  background-color: $dark-1;

  * {
    box-sizing: border-box;
  }

  #marvel-logo {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 0;
    bottom: 0;
    display: block;
    width: 132px;
    height: 51px;
    margin: 0 auto;
    background-image: url("https://i.annihil.us/u/prod/misc/marvel.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: 2px solid #f0141e;
    background-color: #f0141e;
    box-sizing: border-box;
  }

  nav {
    margin: 0 auto;
    text-align: center;
  }

  ol {
    max-width: 1240px;
    padding: 0 20px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 100%;

    li {
      list-style: none;
      flex: 1;
      color: white;
      font-family: $font-family-regular;
      font-size: 13px;
      font-weight: 700;
      letter-spacing: 1px;
      line-height: 16px;
      text-transform: uppercase;
      text-align: center;
      cursor: pointer;
    }
  }

  .upper-nav {
    border-bottom: 1px solid $dark-3;

    ol {
      height: 51px;
      justify-content: end;

      li {
        border-left: 1px solid $dark-3;
        border-right: 1px solid $dark-3;
        padding: 0 15px;
        height: 100%;
        display: flex;
        align-items: center;
        flex: none;

        a {
          fill: $white;
          stroke: $white;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            padding-left: 10px;
          }
        }
      }
    }

    .search svg {
      fill: none;
    }
  }

  .lower-nav {
    height: 40px;

    ol {
      li {
        padding: 0 10px;
        font-family: $font-family-condensed;
        font-size: 12px;

        a {
          height: 100%;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

footer {
  width: 100%;

  nav {
    max-width: 1240px;
    margin: 0 auto;
    padding: 40px 30px 20px;
    display: flex;
    flex-wrap: wrap;

    ol {
      flex: 1;
      display: flex;
      flex-wrap: wrap;

      &:first-of-type {
        padding-right: 15px;
      }

      &:last-of-type {
        padding-left: 15px;
      }

      a {
        flex: 0 0 100%;
        font-size: 12px;
        margin-bottom: 15px;
        color: #c3c3c3 !important;
        text-decoration: none !important;
      }
    }
  }
}

.error-status-page {
  position: relative;
  font-family: $font-family-regular;
  font-weight: normal;
  background-color: $dark-2;
  overflow: hidden;

  .error-status {
    background-color: $white;
    overflow: hidden;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      z-index: 9;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      user-select: none;
    }

    img {
      user-select: none;
    }

    .flex-wrapper {
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      .flex-col {
        flex: 0 0 100%;

        &:first-of-type {
          padding-bottom: 40px;
          border-bottom: 1px solid $gray-3;
          z-index: 1;
        }
      }

      @include breakpoint-final(md) {
        height: 600px;

        .flex-col {
          flex: 1;
          position: relative;
          text-align: center;

          &:first-of-type {
            padding-bottom: 0;
            border-width: 0;
          }
        }
      }
    }

    .toAnimate {
      height: 400px;
      min-width: auto;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      padding: 0;

      @include breakpoint-final(md) {
        height: 100%;
        width: 100%;
        min-width: 440px;
        padding: 0 20px;
      }
    }

    .error-copy {
      position: relative;
      z-index: 10;
      margin-right: 30px;
      text-align: left;
      margin-top: 40px;

      p {
        @extend %description-text;
      }
    }

    .error-image-animate {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      background-size: cover;
    }
  }

  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 9;
    pointer-events: none;

    .particle {
      position: absolute;
      right: 120px;
      top: 60%;
      left: auto;
      bottom: auto;
      max-width: 40px;
      height: auto;

      @include breakpoint-final(md) {
        right: 200px;
        top: 30%;
        max-width: 100%;
      }
    }
  }
}

.error-status {
  &[data-state='1'] {
    .toAnimate {
      background-image: url($bgBlackWidowChar);

      .error-image-animate {
        background-image: url($bgBlackWidowEye);
        width: 75px;
        height: 75px;
        top: auto;
        bottom: 83px;

        @include breakpoint-final(md) {
          width: 110px;
          height: 110px;
          bottom: 126px;
        }
      }
    }

    .error-copy {
      .dynamic-msg::after {
        content: 'Not even the Eye of Uatu sees your request...';
      }
    }
  }

  &[data-state='2'] {
    .toAnimate {
      background-image: url($bgThorChar);
    }

    .error-copy {
      .dynamic-msg::after {
        content: 'You are not worthy...';
      }
    }
  }

  &[data-state='3'] {
    .toAnimate {
      background-size: auto 100%;
      background-position: center bottom;
      background-image: url($bgCaptainMarvelChar);
      animation: pulse 2s infinite linear;
    }

    .error-copy {
      .dynamic-msg::after {
        content: 'HYDRA has stolen this page from the S.H.I.E.L.D. database!';
      }
    }
  }

  &[data-state='4'] {
    &::before {
      background-image: url($bgIronManRain);
      background-size: contain;
    }

    .toAnimate {
      background-image: url($bgIronManChar);
    }

    .error-copy {
      .dynamic-msg::after {
        content: 'Protocol missing... Exiting program...';
      }
    }
  }

  &[data-state='5'] {
    .toAnimate {
      min-width: 500px;
      background-position: center top;
      background-size: contain;
      background-image: url($bgDeadPoolChar);

      @include breakpoint-final(md) {
        background-size: auto;
      }
    }

    .error-copy {
      .dynamic-msg::after {
        content: '$#&%, you broke something! Just kidding...';
      }
    }
  }

  &[data-state='6'] {
    &::before {
      background-image: url($bgCaptainAmericaSmoke);
      background-size: auto;
      background-position: center bottom;
      opacity: 0.4;
      animation: shift 4s infinite linear;
    }

    .toAnimate {
      background-image: url($bgCaptainAmericaChar);
      background-position: right bottom;

      .error-image-animate {
        position: absolute;
        left: -500px;
        right: 0;
        top: auto;
        bottom: -175px;
        margin: 0 auto;
        width: 400px;
        height: 400px;
        z-index: 1;
        background-image: url($bgCaptainAmericaGlitch1),
          url($bgCaptainAmericaGlitch2), url($bgCaptainAmericaGlitch3);
        background-size: contain;
        background-repeat: no-repeat;
        z-index: 1;
        pointer-events: none;
        opacity: 0.5;
        animation: glitch 0.5s infinite linear;
      }

      @include breakpoint-final(md) {
        background-position: right bottom;

        .error-image-animate {
          left: -600px;
        }
      }
    }

    .error-copy {
      .dynamic-msg::after {
        content: 'HYDRA is currently attacking this page!';
      }
    }
  }

  &[data-state='7'] {
    .toAnimate {
      background-image: url($bgMaintenance);
      background-position: center bottom;
      background-size: 400px auto;

      @include breakpoint-final(md) {
        background-size: contain;
      }
    }

    .error-copy {
      .dynamic-msg::after {
        content: 'Please check back shortly.';
      }
    }
  }
}

@media screen and (min-width: 841px) {
  footer {
    nav {
      ol {

        &:first-of-type,
        &:last-of-type {
          padding: 0;
        }

        a {
          flex: 1;
          padding: 0 10px 0 20px;
          text-align: center;
        }
      }
    }
  }
}

@media screen and (min-width: 800px) {
  header {
    .lower-nav {
      ol {
        li {
          padding: 0 18px;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  header {

    .upper-nav,
    .lower-nav {
      ol {
        padding: 0;
      }
    }

    .upper-nav {
      ol {
        border-width: 0;
        padding: 0 30px;
      }
    }

    .lower-nav {
      height: 60px;

      ol {
        a {
          flex: 0 0 25%;
          height: auto;
        }
      }
    }
  }
}

@media screen and (max-width: 440px) {
  .error-status-page {
    .flex-wrapper {
      .error-copy {
        margin: 0 30px;
      }
      
      &.no-pad {
        padding: 0;
      }
    }
  }

  header {
    .lower-nav {
      ol {
        a {
          flex: 0 0 20%;

          li {
            font-size: 11px;

          }
        }
      }
    }
  }
}