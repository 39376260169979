@import './../../scss/includes';

body {
  @extend %description-text;
  
  color: get-brand-color('primary');
}

// First stab at unifying header styles into H tags
h1 {
  font: 500 44px/1 $font-family-condensed-bold, $font-stack-fallback;
  line-height: 1;
  text-transform: uppercase;
  transition: font-size 0.2s ease;
}

h3 {
  @extend %header-medium;
  // font: 800 26px/1 $font-family-condensed, $font-stack-fallback;
  // letter-spacing: 1px;
  // text-transform: uppercase;
}

h4 {
  @extend %headline-medium;
  // font: 400 20px/1.3 $font-family-regular-bold, $font-stack-fallback;
}

h5 {
  @extend %headline-small;
  // font: 400 14px/1.2 $font-family-regular-bold, $font-stack-fallback;
}