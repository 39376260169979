@import '../includes/_breakpoints.scss';

$grid-max-width: 1240px;
$grid-content-max-width: 1200px;
$grid-xs-sm-md-gutter: 30px;
$grid-lg-gutter: 15px;
$grid-xl-gutter: 0px;

$grid-xs-sm-md-padding: $content-xs-sm-md-padding;
$grid-lg-padding: $content-lg-padding;
$grid-xl-padding: $content-xl-padding;

$grid-max-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

/*
 Set display to flex in extra-small screen sizes to resolve
 css-grid minimum-width scaling bug
 */
.grid-wrapper,
.grid-wrapper--nested {
  @include restrain-content();

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* TER-5010 - ensure that child nodes within grid never force a width > 100% of viewport */
  max-width: 100vw;
  overflow-x: hidden;
  /* TER-5166 - ensure that no vertical scrollbars appear in featured component*/
  overflow-y: hidden;

  .flex-wrapper {
    display: flex;
  }
  
  /**
   * EXTRA SMALL SCREEN WIDTHS <= 375
   */
  
  /**
   * 12, 6, and 4 col items are full width on xs screen widths (< 375)
   */
  
   .grid-col-12,
   .grid-col-10,
   .grid-col-8,
   .grid-col-6,
   .grid-col-4,
   .grid-col-4-1-col-padding,
   .grid-col-4-right-align-1-col-padding,
   .grid-col-aside {
     //grid-column: span 12;
     grid-column: 1 / 13;
     grid-template-rows: auto auto auto;
     /* TER-4054: Prevent flexbox bugs within slider at small screen widths */
     display: block;
     width: 100%;
   }
  
   /**
    * 3 and 2 col items are half width on xs screen widths (< 375)
    */
   .grid-col-3,
   .grid-col-3--media,
   .grid-col-2,
   .grid-col-2--3-2,
   .grid-col-2--4-2 {
     grid-column: span 6;
   }
  
   /**
    * 1 col items are third width on xs screen widths (< 375)
    */
   .grid-col-1 {
     grid-column: span 4;
   }
}


/**
 * SMALL SCREEN WIDTHS 376 - 600
 */
//    padding: 0 $grid-xs-sm-md-padding;

/* TER-4409, TER-4448, TER-4436: force display: flex on devices smaller than 376 due to CSS Grid min-width bug */
@include breakpoint-final(xs2) {
  .grid-wrapper,
  .grid-wrapper--nested {
    display: grid;
    grid-template-columns: $grid-max-columns;
    grid-gap: $grid-xs-sm-md-gutter;
    max-width: $grid-max-width;
    // remove flex values after xs screen size
    flex-direction: unset;
    align-items: unset;
    justify-content: unset;
    //margin: 0 auto;

    /**
     * 2 col items are third width on sm screen widths (376 - 600)
     */

    /* TER-5079 - update to explore card behavior in filter grids */
    .grid-col-2 {
      grid-column: span 6;
    }
  }

  /*
   * Accounting for nested version of the grid
   */
  .grid-wrapper {
    .grid-wrapper--nested {
      grid-column: span 12;
      padding: 0;
    }
  }
}

/**
 * MEDIUM SCREEN WIDTHS 601 - 839
 */
@include breakpoint-final(sm) {
  .grid-wrapper,
  .grid-wrapper--nested {
    /**
     * 1 col items are sixth width on md screen widths (601 - 839)
     */
    .grid-col-1 {
      grid-column: span 2;
    }

    /* TER-5079 - update to explore card behavior in filter grids */
    .grid-col-2 {
      grid-column: span 4;
    }

    .grid-col-2--3-2 {
      grid-column: span 3;
    }
    .grid-col-2--4-2 {
      grid-column: span 4;
    }
  }
}

/**
 * LARGE SCREEN WIDTHS 841 - 1240
 */
@include breakpoint-final(md) {
  .grid-wrapper,
  .grid-wrapper--nested {
    /**
    * Gutter and padding shrinks on lg screen widths (840 - 1240)
    */
    .grid-wrapper {
      grid-gap: $grid-lg-gutter;
    }

    /**
    * 12 col grid-items with exactly one column of padding
    */
    .grid-full-1-col-padding {
      grid-column-start: 2;
      grid-column-end: 12;
    }

    /**
    * 5 col grid-items with exactly one column of padding
    */
    .grid-col-5-1-col-padding {
      grid-column-start: 2;
      // visual QA edit: 2/5/2018
      //grid-column-end: 7;
      grid-column-end: 6;
    }

    /**
    * 5 col grid-items, right aligned
    */
    .grid-col-5-right-align {
      grid-column-start: 8;
      grid-column-end: 12;
    }
    /**
    * 4 col grid-items with exactly one column of padding
    */
    .grid-col-4-right-align-1-col-padding {
      grid-column-start: 7;
      grid-column-end: 13;
    }

    /**
    * 10 col items are half width on lg screen widths (840 - 1240)
    */
    .grid-col-10 {
      grid-column: span 10;
    }

    /**
    * 8 col items are half width on lg screen widths (840 - 1240)
    */
    .grid-col-8 {
      grid-column: span 8;
    }

    /**
    * 6 col items are half width on lg screen widths (840 - 1240)
    */
    .grid-col-6 {
      grid-column: span 6;
    }

    .grid-col-5 {
      grid-column: span 5;
    }

    /**
    * 4 col items are third width on lg screen widths (840 - 1240)
    */
    .grid-col-4 {
      grid-column: span 4;
    }

    /**
    * 3 col items are quarter width on lg screen widths (840 - 1240)
    */
    .grid-col-3,
    .grid-col-3--media,
    .grid-col-aside {
      grid-column: span 3;
    }

    /**
    * 2 col items are sixth width on lg screen widths (840 - 1240)
    */
    .grid-col-2 {
      grid-column: span 2;
    }

    /**
    * 2 column items that grow to 3 columns from 601 - 839 then shrink back down to 2 columns on (840 - 1240)
    */
    .grid-col-2--3-2 {
      grid-column: span 2;
    }

    .grid-col-2--4-2 {
      grid-column: span 2;
    }

    /**
    * 1 col items are twelfth width on lg screen widths (840 - 1240)
    */
    .grid-col-1 {
      grid-column: span 1;
    }
  }
}

/**
 * EXTRA LARGE SCREEN WIDTHS > 1240
 */
@include breakpoint-final(xl) {
  /**
   * Gutter and padding removed on xl screen widths (840 - 1240)
   */
  .grid-wrapper {
    grid-gap: $grid-lg-gutter;
    //padding: $grid-xl-padding;

    /**
     * 10 col items are half width on lg screen widths (840 - 1240)
     */
    .grid-col-10 {
      grid-column: span 10;
    }

    /**
     * 8 col items are half width on lg screen widths (840 - 1240)
     */
    .grid-col-8 {
      grid-column: span 8;
    }

    /**
     * 6 col items are half width on lg screen widths (840 - 1240)
     */
    .grid-col-6 {
      grid-column: span 6;
    }

    /**
     * 4 col items are third width on lg screen widths (840 - 1240)
     */
    .grid-col-4 {
      grid-column: span 4;
    }

    /**
     * 3 col items are quarter width on lg screen widths (840 - 1240)
     */
    .grid-col-3,
    .grid-col-3--media {
      grid-column: span 3;
    }

    /**
     * 2 col items are sixth width on lg screen widths (840 - 1240)
     */
    .grid-col-2 {
      grid-column: span 2;
    }
  }
}

/*
* Create a grid wrapper based on the flexbox spec
* Note: This will probably need to be played with a bit as we expand usage
*/
// Max columns
$grid-columns: 12;

.flex-wrapper,
.flex-wrapper--nested {
  @include restrain-content();

  display: flex;
  flex-wrap: wrap;
  overflow-x: hidden;
  overflow-y: hidden;

  .flex-col-auto {
    flex: 0 0 100%;
  }

  .flex-col-half {
    flex: 0 0 50%;
  }

  .flex-col-fixed {
    // placeholder column for using flex-basis on a need-by-need basis
  }

  @include breakpoint-final(md){
    flex-wrap: nowrap;
  
    .flex-col-half {
      flex: 0 0 50%;
    }

    .flex-col-auto {
      flex: 1;
    }
  }

  @for $i from 1 through $grid-columns {
    .flex-col-#{$i} {
      display: block;
      width: 100%;
      flex: 0 0 100%;

      @include breakpoint-final(md){
        width: auto;
        flex: 0 0 calc(100% / (12 / #{$i}));
      }
    }
  }
}

.site-wrapper {
  @include restrain-content();
}