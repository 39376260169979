
/* HEADERS */
%hero-header {
  font: 500 26px/1 $font-family-condensed-bold, $font-stack-fallback;
  line-height: 1;
  text-transform: uppercase;
  transition: font-size 0.2s ease;

  @include breakpoint-final(lg) {
    font-size: 44px;
  }
}

%header-large {
  font: 400 34px/1 $font-family-condensed-bold, $font-stack-fallback;
  letter-spacing: 1px;
  text-transform: uppercase;
}

%header-medium {
  font: 800 26px/1 $font-family-condensed, $font-stack-fallback;
  letter-spacing: 1px;
  text-transform: uppercase;
}

%section-small-header {
  font: 500 24px/1 $font-family-regular, $font-stack-fallback;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

%section-small-header-medium {
  @extend %section-small-header;

  font: 500 24px/1.1 $font-family-condensed-bold, $font-stack-fallback;
}

%sub-header {
  font: 500 15px/22px $font-family-regular, $font-stack-fallback;
  letter-spacing: 1px;
  text-transform: uppercase;
}



/* Labels/Tabs/CTAs */
%headline-large {
  font: 400 26px/1.2 $font-family-regular-bold, $font-stack-fallback;
}

%headline-medium {
  font: 400 20px/1.3 $font-family-regular-bold, $font-stack-fallback;
  letter-spacing: 0;
}

%headline-small {
  font: 400 14px/1.2 $font-family-regular-bold, $font-stack-fallback;
}

%headline-extra-small {
  font: 400 11px/1.2 $font-family-regular-bold, $font-stack-fallback;
}

%label-large {
  font: 400 16px/1.1 $font-family-condensed-bold, $font-stack-fallback;
  letter-spacing: 1px;
  text-transform: uppercase;
}

%label-small {
  font: 400 12px/1 $font-family-condensed-bold, $font-stack-fallback;
  color: $brand-red;
  letter-spacing: 1px;
  text-transform: uppercase;
}

%button-cta {
  font: 800 14px/1 $font-family-regular-bold, $font-stack-fallback;
  text-transform: uppercase;
}

// Components headline/title
%description-text {
  font: 400 16px/1.3 $font-family-regular, $font-stack-fallback;
  letter-spacing: 0.5px;
}

%article-text {
  font: 400 14px/1.5 $font-family-open-sans, $font-stack-fallback;
}

/* Other */
%legal-text {
  font: normal 11px/1.2 $font-family-regular, $font-stack-fallback;
  letter-spacing: 0;
}
