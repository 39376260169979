//import all page-level styles
@import './_watch';

.terrigen {
  /* MVL-1411 - move 100vh height + black bg color to body selector */
  height: 100vh;
  
  &.pageLock {
    overflow: hidden;

    .page {
      &.mvl--ios {
        transform: none;
      }
    }
  }
}

.page {
  /* MVL-1411 - remove explicit 100vh height and change page bg to white in order to resolve preview draft bg color bug */
  // background-color: $brand-light;
  //background-color: $dark-5; // Match footer color for when the body content is less than viewport height
  /* TER-3982: Fix to eliminate blackscreen flicker render issues with ios -webkit-overflow-scrolling: touch */
  overflow-y: auto;

  @include breakpoint-final(sm) {
    overflow-x: hidden;
  }
  &.mvl--ios {
    transform: translate3d(0, 0, 0);
    .navigation__container--navs {
      position: relative;
      transform: none;
    }
  }

  &__component {
    /* TER-4407:  */
    //margin: 80px 0 0 0;
    /* MVL-254: reduce white space between components, setting all whitespace to  */
    //padding: 80px 0 0 0;
    padding: 0px 0 0 0;

    &--home.page__component--two_column {
      .two-column__rail {
        margin-top: 10px;
      }
    }

    &--two_column {
      // REMOVE PADDING FOR CONSECUTIVE TWO-COLUMN COMPONENTS
      + .page__component--two_column {
        padding: 0;
      }
    }

    /* MVL-1106 - It appears MVL-299 might have nerfed these styles on the whole text box, so adding them back */
    /* MVL-1379 - Band-aid fix: move global h2 styles for comics report until we can scrub global styles altogether; */
    &--character-comics-report,
    &--character-on-screen-report {
      h2, h4, h5, h6 {
        margin-top: 0;
      }
    }

    &--promo {
      padding: 0;
      margin: 0;
    }

    &--purchase {
      .slider {
        &__wrapper {
          min-height: 300px;
        }
      }
    }

    &--search {
      .two-column.flex-wrapper {
        flex-direction: column-reverse;
      }
    }

    &.firstComponent,
    &:first-child {
      margin-top: 0;
      padding: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:empty {
      padding: 0;
      display: none;
    }

    /* MVL-254 padding applied to individual slider components pages because whitespace */
    &--slider {
      padding: 10px 0 40px 0;
    }
  }
}

.section {
  &__color {
    &__dark {
      background-color: $brand-dark;
    }

    &__light {
      background-color: $brand-light;
    }
  }
}

/* TER-0001 - testing padding: 10px 0 0 0; across homepage-only components */
/* MVL-406 - remove padding from promos while keeping test padding */
.home-page{
  .page {
    &__component {
      padding: 0px 0 0 0;

      &.firstComponent,
      &:first-child,
      &--promo {
        padding-top: 0;
      }
    }
  }
}

/* MVL-254, MVL-594 padding applied around video container because whitespace */
.detail-trailers-page,
.detail-new-form-show-page {
  .page {
    &__component {
      .content-container {
        padding: 40px 0 40px 0;
      }
    }
  }
}

/* MVL-254, MVL-594 padding applied to two-column components within character pages because whitespace */
.item-page,
.character-page,
.character-comics-report-page,
.character-on-screen-report-page {
  .page {
    &__component {
      .two-column {
        padding-top: 40px;
      }
    }
  }
}

/* MVL-254, MVL-594 padding applied to two-column components within character pages because whitespace */
.group-page,
.group-comics-full-report-page,
.group-on-screen-report-page {
  .page {
    &__component {
      .two-column{
        padding-top: 40px;
      }
      > .slider {
        padding: 40px 0 0 0;
      }
    }
  }
}

/* MVL-408 creating the proper grid for 2-column also requires the proper padding and in the interest
*  of not messing with articles at this time, and since we already doing this... override the grid padding */
.article {
  .two-column {
    &,
    &__content,
    &__rail .rail-featured {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .two-column {
    &__rail .rail-featured {
      padding-left: 0;
      padding-right: 0;
      display: none;
    }
  }
}

.socialPost {
  &__fb-video {
    iframe {
      max-width: 100%;
      height: 350px;
    }
  }
  &__instagram {
    text-align: center;
    iframe {
      max-width: 500px;
    }
  }
  &__youtube {
    position: relative;
    padding-bottom: 56.25% !important; /* 16:9 */
    padding-top: 25px;
    height: 0;
    width: 100%;
    margin: 0 auto;
    iframe{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

@include breakpoint-final(xs) {
  .socialPost {
    &__fb-video {
      iframe {
        height: 550px;
      }
    }
  }
}

@include breakpoint-final(sm) {
  .socialPost {
    &__fb-video {
      iframe {
        height: 650px;
      }
    }
  }
}

@include breakpoint-final(md) {
  .page {
    &__component {
      &--feed {
        margin: 0;
      }
      &--search {
        .two-column.flex-wrapper {
          flex-direction: row;
        }
      }
    }
  }
}

@include breakpoint-final(lg) {
  .page {
    &__component {
      &--article {
        .two-column.flex-wrapper {
          .rail-featured {
            display: block;
          }
        }
      }
    }
  }
}

@include breakpoint-final(xl) {
  .socialPost {
    &__youtube {
      padding-bottom: 40.25%;
    }
  }
}