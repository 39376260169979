.center {
  margin: 0 auto;

  &--x {
    left: 50%;
    position: relative;
    transform: translateX(-50%);

    &__abs {
      position: absolute;
    }
  }

  &--y {
    top: 50%;
    position: relative;
    transform: translateY(-50%);

    &__abs {
      position: absolute;
    }
  }

  &--xy {
    left: 50%;
    top: 50%;
    position: relative;
    transform: translate(-50%, -50%);

    &__abs {
      position: absolute;
    }
  }
}
