@import './_variables';

/*
Clearfix
------------------------- */
@mixin clearfix() {
  & {
    *zoom: 1;
  }
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

//sitewide utility
@mixin clean-link {
  a{
    text-decoration: none;
  }
  :visited {
     color: $brand-dark;
   }
  :active {
     color: $brand-dark;
   }
  :link {
     color: $brand-dark;
   }
  :hover {
     color: $brand-red;
   }
}

@mixin z-index($key) {
  z-index: z-index($key);
}

@mixin marvel-text-anchor {
  color: $link-color;
  text-decoration: underline;
  transition: color 0.12s ease-in;

  &:hover {
    color: $link-hover-color;
    text-decoration: none;
  }
}

@mixin clean-button {
  background-color: #888;
  border-radius: 3px;
  color: $brand-light;
  margin: 0 5px 5px 0;
  padding: 10px 20px;
}

@mixin playbox($squareside) {
  width: $squareside;
  height: $squareside;
}

@mixin powerslash($width: 20px, $height: 2px, $color: $gold-1) {
  position: relative;

  &::before,
  &::after {
    background-color: $color;
    content: '';
    display: block;
    height: $height;
    position: absolute;
    transform: rotate(-45deg) skewX(45deg);
    width: $width;
  }

  &::before {
    left: 53px;
    top: -8px;
    transform-origin: left bottom;
  }

  &::after {
    left: -6px;
    top: calc(100% + 3px);
    transform-origin: right top;
  }
}

@mixin cardshadow($color: $black) {
  box-shadow: 0 7px 17px -8px rgba(0, 0, 0, 0.8);
}

@mixin prephovertransform {
  transform: translate3d(0) scale(1, 1);
  transition: all 0.17s ease-in-out;
}

@mixin bottomCornerCut {
  border-bottom-color: transparent; /* this is the actual slant*/
  border-left-color: transparent; /* this is the actual slant*/
  border-right-color: $brand-light; /* this is the actual slant*/
  border-style: solid;
  border-top-color: transparent;
  border-width: 12px 12px 0 0;
  bottom: 0;
  content: '';
  position: absolute;
  right: 0;
  top: auto;
  z-index: $z-index-low-middle2;
}

@mixin slidingDoorBGColorHoverAnimation {
  background: $brand-red;
  bottom: 100%;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  transition: transform 0.3s;
  width: 100%;
  @include z-index(lowest);
}

@mixin cardEyebrow {
  background: $brand-dark;
  color: $brand-light;
  font-family: $font-family-condensed-bold;
  font-size: $card-eyebrow;
  left: 0;
  letter-spacing: 1px;
  padding: 2px 7px 0px 7px;
  position: absolute;
  text-transform: uppercase;
  top: -25px;
}

@mixin cardImage {
    display: block;
    height: 100%;
    margin: 0;
    object-fit: cover;
    padding: 0;
    width: 100%;
}

@mixin lobOpacityFade {
  opacity: 1;
  transform: translateZ(0);
  transition: all 0.2s linear;
}

@mixin noFunDrJones {
  animation-duration: 0s !important;
  transition:none !important;
}

@mixin restrain-content() {
  width: 100%;
  max-width: $content-max-width;
  padding-right: $content-xs-sm-md-padding;
  padding-left: $content-xs-sm-md-padding;
  margin: 0 auto;

  @include breakpoint-final(lg) {
    padding-right: $content-lg-padding;
    padding-left: $content-lg-padding;
  }

  // Padding should always be present in order to keep all content within the 1200px max area
  // @include breakpoint-final(xl) {
  //   padding: 0 $content-xl-padding;
  // }
}

@mixin browser($browsers...) {
  @each $browser in $browsers {
   @if $browser == 'ie' {
     @media #{map-get($browsers-map, $browser)} {
       @content;
     }
   } @else if $browser == 'edge' {
     @supports #{map-get($browsers-map, $browser)} {
       @content;
     }
   }
   @else {
     @content;
     }
  }
}

@mixin imageBackground() {
  &::after {
    position: absolute;
    content: '';
    display: block;
    background-color: $brand-dark;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

@mixin carouselArrowBg($carousel-trapezoid) {
  background-image: url($carousel-trapezoid);
  background-repeat: no-repeat;
  height: 135px;
  width: 75px;
}

/**
* Used in components to center load more buttons, loaders and other self contained content
*/
@mixin centerContent() {
  align-content: center;
  text-align: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}