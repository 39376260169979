@import '../../scss/includes';

$bevel-edge-width: 16px;

$logo-max-height: 36px; // 72px HD
$logo-max-width: 120px; // 120px HD

$logo-absolute-positioning-offset: -18px;
$logo-left-margin: 10px;

$bevel-edge-safety-width: calc(
	#{$bevel-edge-width} - 1px
); // With dynamic widths we can get floats, this prevents them from showing on solid buttons

$repeating-gradient-size: $bevel-edge-width calc(#{$bevel-edge-width} + #{$bevel-edge-width} / 2);
$button-max-width: auto;

$hover-alpha: 0.4;
$btn-font-family: $font-family-regular;

// Red solid button
$solid-red-color: $brand-red;
$solid-red-hover-color: $red-2;
$solid-red-text-color: $brand-light;

// Blue solid button
$solid-blue-color: rgb(58,103,210);
$solid-blue-hover-color: rgb(88,136,249);
$solid-blue-text-color: $brand-light;

// White solid button
$solid-light-color: $brand-light;
$solid-light-hover-color: $gray-4;
$solid-light-text-color: $brand-dark;

// Red outline button
$outline-red-color: $brand-red;
$outline-red-hover-color: $brand-red-rgb;
$outline-red-text-color: $brand-red;

// Light outline button
$outline-light-color: $brand-light;
$outline-light-hover-color: $brand-light-rgb;
$outline-light-text-color: $brand-light;

// Dark outline button
$outline-dark-color: $brand-dark;
$outline-dark-hover-color: $brand-dark-rgb;
$outline-dark-text-color: $brand-dark;

@mixin fillButton( $start-color, $hover-color, $text-color ) {
	& {
		&::before,
		&::after {
			background-color: $start-color;
			border-color: $start-color;
			transition: none;
		}

		&::before {
			margin-left: $bevel-edge-width;
		}

		&::after {
			margin-right: $bevel-edge-width;
		}
	}

	.innerFill {
		color: $text-color;
		background-color: $start-color;
		transition: none;
		text-align: center;

		&::before,
		&::after {
			border-color: $start-color transparent;
			transition: none;
		}

		img {
			/* MVL-2616 - Adding image logos to buttons. This removing max-height will break things horribly. Wheee! */
			max-height: $logo-max-height;
			width: auto;
			text-align: center;
			margin: 0 auto !important;
		}
	}

	&:hover {
		&::before,
		&::after {
			background-color: $hover-color;
			border-color: $hover-color;
			opacity: 1;
		}

		.innerFill {
			background-color: $hover-color;
			border-color: $hover-color;
			color: $text-color;

			&::before {
				border-color: $hover-color transparent;
				opacity: 1;
			}
			&::after {
				border-color: $hover-color transparent;
				opacity: 1;
			}
		}

		div {
			background-color: transparent;

			&::before,
			&::after {
				border-color: transparent;
			}
		}
	}
}

@mixin outlineButton( $start-color, $hover-color, $text-color ) {
	& {
		&::before,
		&::after {
			border-color: $start-color;
			transition: none;
			max-width: calc(100% - #{$bevel-edge-safety-width});
		}

		&::before {
			border-width: 1px 1px 0 0;
			right: 0;
		}

		&::after {
			border-width: 0 0 1px 1px;
			left: 0;
		}
	}

	.innerFill {
		background-color: transparent;
		border-left: 1px solid $start-color;
		border-right: 1px solid $start-color;
		color: $text-color;
		opacity: 1;
		transition: none;
		text-align: center;
		line-height: 16px;

		&::before,
		&::after {
			background-size: $repeating-gradient-size;
			background-image: repeating-linear-gradient(
				-45deg,
				rgba($start-color, 1),
				rgba($start-color, 1) 1px,
				transparent 1px,
				transparent $bevel-edge-width
			);
			transition: none;
		}
		a {
			display: block;
		}
	}

	&:hover {
		&::before,
		&::after {
			opacity: $hover-alpha;
			transform: translate3d(0);
		}

		& .innerFill {
			border-color: rgba($hover-color, $hover-alpha);

			&::before,
			&::after {
				opacity: $hover-alpha;
			}
		}
	}
}

.cta-btn {
	background-color: transparent;
	border-radius: 0;
	border: none;
	box-sizing: border-box;
	cursor: pointer;
	display: inline-block;
	font-weight: bold;
	margin: 15px auto;
	overflow: hidden;
	padding: 0;
	position: relative;
	letter-spacing: 0px;
	width: auto;

	/* MVL-1315 - Regression from MVL-1005 */
	@include z-index(lower);

	.innerFill {
		padding: 0 35px 0 35px;
		text-align: center;

		@extend %button-cta;

		&::before {
			left: 0;
			top: 0;
		}

		&::after {
			bottom: 0;
			right: 0;
			transform: rotate(180deg);
		}

		&::before,
		&::after {
			border-color: transparent;
			border-style: solid;
			border-width: 0 0 $bevel-edge-width $bevel-edge-width;
			box-sizing: border-box;
			content: "";
			display: block;
			position: absolute;
		}

		&.hasLogo {
			display: flex;
			flex-direction: row;
			align-items: center;

			.btn-img-wrapper {
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				//width: 60px;
				max-height: 36px;
				order: 2;
				margin-left: $logo-left-margin;

				.img__wrapper {
					position: absolute;
					height: $logo-max-height;
					top: $logo-absolute-positioning-offset;
				}
			}
		}
	}

	&::before,
	&::after {
		border-style: solid;
		box-sizing: border-box;
		content: "";
		display: block;
		height: $bevel-edge-width;
		/* MVL-2616 - need to take this out in order to prevent overlap issues */
		//position: relative;
	}

	&::before {
		border-width: 0 0 $bevel-edge-width $bevel-edge-width;
		margin-left: $bevel-edge-width;
		top: 0;
	}

	&::after {
		border-width: $bevel-edge-width $bevel-edge-width 0 0;
		bottom: 0;
		margin-right: $bevel-edge-width;
	}

	&:focus {
		outline: 2px solid $brand-gold;
	}

	/* Button variants */
	&.cta-btn--outline {
		&.cta-btn--red {
			@include outlineButton( $outline-red-color, $outline-red-hover-color, $outline-red-text-color );
		}

		&.cta-btn--light {
			@include outlineButton( $outline-light-color, $outline-light-hover-color, $outline-light-text-color );
		}

		&.cta-btn--dark {
			@include outlineButton( $outline-dark-color, $outline-dark-hover-color, $outline-dark-text-color );
		}
	}

	&.cta-btn--solid {
		&.cta-btn--red {
			@include fillButton( $solid-red-color, $solid-red-hover-color, $solid-red-text-color );
		}

		&.cta-btn--blue {
			@include fillButton( $solid-blue-color, $solid-blue-hover-color, $solid-blue-text-color );
		}

		&.cta-btn--light {
			@include fillButton( $solid-light-color, $solid-light-hover-color, $solid-light-text-color );
		}
	}
}
