@import '../../scss/includes';

.watch-page{
  //border: 11px solid $red;
}

/* TER-5052 - remove power slashes on all detail-new-form-show page miniMastheads */
.detail-new-form-show-page {
	.masthead {
		&.miniBackground {
			.masthead__container::before,
			.masthead__container::after {
				display: none;
			}
		}
	}
}
