$font-size-height: 20px;

html {
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: $font-size-height;
  line-height: 1.3;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul li {
  margin: 0;
  padding: 0;
}

ol {
  margin: 0;
  padding: 0;
}
p {
  padding: 0;
  margin: 0;
  // @include rem("margin", 0, 0, 5, 0);
}

h1, h2, h3, h4, h5, h6 {
  padding: 0;
  // @include rem("margin", 0, 0, 10, 0);
  font-weight: normal;
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

button {
  cursor: pointer;
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

figure {
  margin: 0;
  padding: 0;
}

div {
  margin: 0;
  padding: 0;
}

section {
  box-sizing: border-box;
}

*, ::after, ::before {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

:focus {
  outline-color: $gold-3;
}
