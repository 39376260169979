/*
Colors
------------------------- */

/*
Primary Colors [for testing]
---------- */
$green: #008000;
$red: #f00;
$blue: #00f;
$black: #000;
$white: #fff;
$orange: #ffa500;

/*
Colors with Alpha Transparency
---------- */
$brand-red-rgb: rgb(235, 35, 40);
$brand-dark-rgb: rgb(32, 32, 32);
$brand-dark-rgba: rgba(32, 32, 32, 0.5);
$brand-light-rgb: rgb(255, 255, 255);

/*
 Red
 -------
 -red-1 changed from #eb2328 to #e62429 MVL-1450
 --- */
$red-1: #e62429;
$red-2: #9f0013;

/*
Dark
---------- */
$dark-1: #202020;
$dark-2: #151515;
$dark-3: #393939;

/*
Gold
---------- */
$gold-1: #c6a972;
$gold-2: #ae8b50;
$gold-3: #e8b63e;

/*
Gray and White
---------- */
$gray-1: #999;
$gray-2: #f2f2f2;
$gray-3: #e1e1e1;
$gray-4: #bbb;


$transparent: transparent;

/*
  Preview Colors
 ----------*/
  $draft: #db81ff;
  $needs-review: #8fccff;
  $archived: #ff9574;

/*
Brand colors
---------- */
// Marvel Red
$brand-1: $red-1;
$brand-red: $red-1;

// Darkness Black
$brand-2: $dark-2;
$brand-dark: $dark-2;

// Light White
$brand-3: $white;
$brand-light: $white;

// Prestige Gold
$brand-4: $gold-1;
$brand-gold: $gold-1;

// Dark Gray
$brand-5: $gray-1;
$brand-dk-gray: $gray-1;

// Medium Gray
$brand-6: $gray-4;
$brand-md-gray: $gray-4;

// Light Gray
$brand-7: $gray-2;
$brand-lt-gray: $gray-2;

$brand-on-light: (
  'primary': $dark-1,
  'action': $red-1,
  'secondary': $gray-1,
  'disabled': $gray-4,
  'accent': $gold-1
);

$brand-on-dark: (
  'primary': $white,
  'action': $red-1,
  'secondary': $gray-1,
  'disabled': $dark-3,
  'accent': $gold-3
);

$brand-colors: (
  'light': $brand-on-light,
  'dark': $brand-on-dark
);

/*
SVG backgrounds
---------- */
$carousel-trapezoid: "data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 69 126'%3e%3cimage width='82' height='142' xlink:href='DD13D991063EB32A.png' transform='translate(-19 -8)' overflow='visible' opacity='.36'/%3e%3cpath fill='%23fff' d='M.2 14l37.6 26-.6 44.8L-.2 112z'/%3e%3cpath fill='none' stroke='%23e5e5e5' d='M.2 14l37.6 26-.6 44.8L-.2 112z'/%3e%3c/svg%3e";


/*
Links
---------- */
$link-color: $brand-red;
$link-hover-color: $red-2;


/*
Typography
------------------------- */
$font-family-regular: 'Roboto';
$font-family-regular-bold: 'Roboto Bold';
$font-family-condensed: 'RobotoCondensed';
$font-family-condensed-bold: 'RobotoCondensed Bold';
$font-family-open-sans: 'Open Sans';
$font-stack-fallback: 'Trebuchet MS', Helvetica, Arial, sans-serif;

$font-weight-regular: 400;
$font-weight-bold: 800;

$font-size-xxl: 100px;
$font-size-lg: 18px;
$font-size-md: 14px;
$font-size-sm: 10px;
$font-size-height: 20px;


// Currently defined as Hero Headline
$font-size-h1: 70px;
$font-weight-h1: $font-weight-bold;
$font-spacing-h1: 2.5px;
$font-height-h1: 60px;

$font-size-h2: 20px;
$font-weight-h2: $font-weight-bold;
$font-height-h2: 24px;

$font-size-h3: 16px;
$font-weight-h3: $font-weight-bold;
$font-height-h3: 20px;

$font-spacing-h2: 4px;
$font-spacing-h3: 10px;
$font-spacing-h4: 7.2px;

$font-size-section-header: 26px;
$font-weight-section-header: $font-weight-bold;
$font-spacing-section-header: 12px;
$font-height-section-header: 28px;

// Currently defined as Content Tag
$font-size-category-heading: 12px;
$font-weight-category-heading: $font-weight-bold;
$font-spacing-category-heading: 14px;
$font-line-height-category-heading: 12px;


/*
Headers and Tabs
---------- */
$font-size-large-header: 40px;
$font-letter-spacing-large-header: 4px;
$font-line-height-large-header: 40px;

$font-size-section-header: 30px;
$font-letter-spacing-section-header: 10px;
$font-line-height-section-header: 30px;

$font-size-hero-eyebrow-header: 18px;
$font-letter-spacing-hero-eyebrow-header: 7.2px;
$font-line-height-hero-eyebrow-header: 18px;

$font-size-section-subheader: 15px;
$font-letter-spacing-section-subheader: 1px;
$font-line-height-section-subheader: 22px;

$font-size-tab: 13px;
$font-letter-spacing-tab: 1px;


/*
Headlines and Body Copy
---------- */
$font-letter-spacing-headline-body-copy: 0px;

$font-size-xl-headline: 26px;
$font-line-height-xl-headline: 28px;

$font-size-large-headline: 22px;
$font-line-height-large-headline: 26px;

$font-size-medium-headline: 18px;
$font-line-height-medium-headline: 22px;

$font-size-small-headline: 14px;
$font-line-height-small-headline: 16px;

$font-size-large-body-copy: 18px;
$font-line-height-large-body-copy: 24px;

$font-size-standard-body-copy: 14px;
$font-line-height-standard-body-copy: 22px;


/*
Font baselines
---------- */
$font-family-base: $font-family-regular;
$font-weight-base: $font-weight-regular;
$font-size-base: $font-size-large-body-copy;

/*
Grid
------------------------- */
// used to determine the space between rows
$grid-row-gap: 10%;


/*
Media Queries Breakpoints
------------------------- */
$screen-xs: 319px;
$screen-xs-min: 320px;

$screen-xs2: 374px;
$screen-xs-min2: 375px;

$screen-sm: 600px;
$screen-sm-min: 601px;

/* MVL-2811 Creating new carousel-specific breakpoint for 767px */
$screen-sm2: 767px;
$screen-sm2-min: 768px;

$screen-md: 838px;
$screen-md-min: 839px;

$screen-lg: 960px;
$screen-lg-min: 961px;

$screen-xl: 1240px;
$screen-xl-min: 1241px;

$screen-max-width: 1400px;

$screen-map-final: (
  xs: $screen-xs-min,
  xs2: $screen-xs-min2,
  sm: $screen-sm-min,
  sm2: $screen-sm2-min,
  md: $screen-md-min,
  lg: $screen-lg-min,
  xl: $screen-xl-min,
  xxl: $screen-max-width
);

$z-index-lowest: -1;
$z-index-low-middle2: 40;

$z-indices: (
  lowest: -1,
  lower: 10,
  lowmid: 30,
  lowmid2: 40,
  middle: 50,
  middle2: 55,
  highmid: 80,
  higher: 90,
  highest: 100
);


/*
Page Builder Container
------------------------- */
$content-max-width: 1240px;
$content-xs-sm-md-padding: 30px;
$content-lg-padding: 20px;
$content-xl-padding: 0px;

/*
Pathing
------------------------- */
$asset-path: '/static/fonts/';
$font-path: 'https://i.annihil.us/u/prod/marvel/font/roboto/';
$img-path: './static/images/';


/*
Cards and Media Objects
------------------------- */
$card-eyebrow: 14px;

$card-headline-sm-1: 11px;
$card-headline-sm-2: 14px;

$card-headline-md-1: 16px;
$card-headline-md-2: 15px;

$card-headline-lg-1: 1.2;
$card-headline-lg-2: 26px;
$card-headline-lg-3: 30px;

$card-footer-secondary-micro-sm: 11px;
$card-footer-secondary-micro-md: 14px;
$card-footer-secondary-micro-lg: 18px;
$card-footer-line-height: 12px;

/*
Bioheader, Collapsible, Accordion
------------------------- */
$collapsible-padding: 1em;

/*
POWER SLANT
------------------------ */
$power-slant: polygon(0% 0%, 0% 100%, 100% 92%, 100% 0%);
$power-slant-dropshadow: polygon(0% 0%, 0% 98%, 100% 92%, 100% 0%);
$power-slant-mobile: polygon(0% 0%, 0% 100%, 100% 95%, 100% 0%);
$power-slant-dropshadow-mobile: polygon(0% 0%, 0% 99%, 100% 95%, 100% 0%);


/*
Browser selectors
----------------- */
$browsers-map: (
  ie: 'all and (-ms-high-contrast: none), (-ms-high-contrast: active)',
  edge: '(-ms-ime-align: auto)'
);
