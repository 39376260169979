@font-face {
  font-family: RobotoCondensed;
  src: url($font-path + "RobotoCondensed-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'RobotoCondensed Bold';
  src: url($font-path + "RobotoCondensed-Bold.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  src: url($font-path + "Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Roboto Bold';
  src: url($font-path + "Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  src: url($font-path + "open-sans-v15-latin-regular.eot?#iefix");
  src: url($font-path + "open-sans-v15-latin-regular.eot?#iefix") format("eot"), url($font-path + "open-sans-v15-latin-regular.woff2") format("woff2"), url($font-path + "open-sans-v15-latin-regular.woff") format("woff"), url($font-path + "open-sans-v15-latin-regular.ttf") format("truetype"), url($font-path + "open-sans-v15-latin-regular.svg") format("svg");
}
